<template>
  <main>
    <!-- application form account-info change -->
    <section id="register_points_search_payment">
      <div class="w1300 layout_container">

        <!-- 表單主標題 -->
        <div class="main_title_box">
          <h3 class="main_title txt-bold">{{ orderType }}繳費</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <!-- table area -->
        <div class="table_box">
          <table class="txt-center">
            <thead>
              <tr>
                <th class="td-main txt-left"><p>{{ th1 }}</p></th>
                <!-- <th class="min100"><p>{{ th2 }}</p></th> -->
                <th class="min130"><p>{{ th3 }}</p></th>
              </tr>
            </thead>
            <tbody id="table_data">

              <tr v-for="item in orderList" :key="item.uuid">
                <td :data-title="th1" class="td-main txt-left">
                  <p><template v-if="item.content">{{ item.content }}</template></p>
                </td>
                <!-- <td :data-title="th2"><p>{{ eventPrice }}</p></td> -->
                <td :data-title="th3">{{ item.price }}</td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="form_box">
          <form>
            <div class="form_interactive">
              <div class="fixed_info w50">
                <p class="fixed_info_title">付款方式</p>
                <p class="fixed_info_content">信用卡</p>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">總金額</p>
                <p class="fixed_info_content">{{ orderAmount }}</p>
              </div>
            </div>
          </form>

          <!-- 綠界金流按鈕 -->
          <div v-html="resHtml"></div>
          <div class="errMsg txt-center txt-red">
            <p class="h3">{{ paymentErrorMsg }}</p>
          </div>
        </div>

      </div>
    </section>
  </main>
</template>
<script>
/** Library */
import _ from 'lodash';

/** Functions */
import { mapActions } from 'vuex';
import { orderView, orderPay } from '@/lib/http';
import { routeConverter } from '@/lib/public';

export default {
  name: 'Confirm',
  data() {
    return {
      orderType: '重新',
      th1: '費用名稱',
      th2: '收據抬頭',
      th3: '金額',
      orderID: '',
      orderInfo: {},
      paymentErrorMsg: '',
      resHtml: '',
      formData: {
        order_uuid: '',
        pay_id: '',
        detail: [],
        return_url: '',
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.order_id) {
      if (routeConverter(this.$route.path)) {
        this.orderType = routeConverter(this.$route.path);
      }

      if (this.$cookies.get('orderPaymentInfo')) {
        this.orderID = this.$route.query.order_id;
        this.formData = this.$cookies.get('orderPaymentInfo');

        /** 取得付款資訊 */
        orderView({ uuid: this.orderID }).then((result) => {
          this.orderInfo = _.get(result, 'data.result.data', {});
        });

        /** 呼叫金流API */
        orderPay(this.formData).then((result) => {
          if (result.data.result.html) {
            this.resHtml = result.data.result.html;
          } else {
            this.paymentErrorMsg = result.data.message;
          }
        });
      } else {
        this.openModal({
          url: this.redirectURL,
          message: this.redirectMsg,
        });
      }
    } else {
      this.openModal({
        url: this.redirectURL,
        message: this.redirectMsg,
      });
    }
  },
  computed: {
    orderList() {
      return _.get(this.orderInfo, 'order_details');
    },
    orderAmount() {
      return _.get(this.orderInfo, 'total', '');
    },
    redirectURL() {
      let target = '';
      switch (this.orderType) {
        case '活動紀錄':
          target = '/register/record';
          break;
        case '費用查詢':
          target = '/account/search/fee';
          break;
        default:
          break;
      }
      return target;
    },
    redirectMsg() {
      let target = '';
      switch (this.orderType) {
        case '活動紀錄':
          target = '請返回活動紀錄選擇欲繳費項目';
          break;
        case '費用查詢':
          target = '請返回會員費用查詢選擇欲繳費項目';
          break;
        default:
          break;
      }
      return target;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
  },
};
</script>
